<template>
  <div>
    <!-- begin::Card -->
    <div class="card card-custom gutter-b">
      <div class="card-header flex-nowrap border-0 pt-6 pb-0">
        <div class="card-title">
          <h3 class="card-label">
            Gestión de zonas
            <span class="d-block text-muted pt-2 font-size-sm"
              >Aqui se podra visualizar, crear, actualizar y eliminar todas las
              zonas.</span
            >
          </h3>
        </div>
        <div class="card-toolbar">
          <!--begin::Button New Record-->
          <v-btn
            elevation="0"
            color="primary"
            @click="showMdlCreate"
            v-if="existInArray('Agregar', currentPageActions)"
          >
            <span class="svg-icon svg-icon-md svg-icon-white mr-1">
              <inline-svg src="media/svg/icons/Home/Book-open.svg"></inline-svg>
            </span>
            Crear zona
          </v-btn>
          <!--end::Button New Record-->
        </div>
      </div>
      <div class="card-body">
        <!-- begin::Search Form -->
        <div class="mb-5">
          <div class="row align-items-center">
            <div class="col-lg-9 col-xl-8">
              <div class="row align-items-center">
                <div class="col-md-4 my-2 my-md-0">
                  <v-text-field
                    outlined
                    dense
                    hide-details
                    prepend-icon=""
                    prepend-inner-icon="mdi-magnify"
                    placeholder="Buscar..."
                    v-model="zones.search"
                  ></v-text-field>
                </div>
              </div>
            </div>
          </div>
        </div>
        <!-- end::Search Form -->
        <!--begin: Datatable-->
        <v-card>
          <v-data-table
            :headers="zones.headers"
            :items="zones.data"
            :search="zones.search"
            :items-per-page="10"
            :loading="zones.isLoading"
          >
            <template v-slot:[`item.image`]="{ item }">
              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <v-icon
                    @click="selectZone(item), showImgDialog()"
                    color="blue"
                    v-bind="attrs"
                    v-on="on"
                    >mdi-file-image-outline</v-icon
                  ></template
                >
                <span>Imagen</span>
              </v-tooltip>
            </template>
            <!-- begin: Actions -->
            <template v-slot:[`item.actions`]="{ item }">
              <template v-if="existInArray('Editar', currentPageActions)">
                <v-tooltip bottom>
                  <template v-slot:activator="{ on, attrs }">
                    <v-icon
                      @click="
                        selectZone(item);
                        showMdlUpdate();
                      "
                      color="amber"
                      v-bind="attrs"
                      v-on="on"
                    >
                      mdi-pencil
                    </v-icon>
                  </template>
                  <span>Editar</span>
                </v-tooltip>
              </template>
              <template v-if="existInArray('Eliminar', currentPageActions)">
                <v-tooltip bottom>
                  <template v-slot:activator="{ on, attrs }">
                    <v-icon
                      class="ml-5"
                      @click="
                        selectZone(item);
                        showMdlDelete();
                      "
                      color="red"
                      v-bind="attrs"
                      v-on="on"
                    >
                      mdi-delete
                    </v-icon>
                  </template>
                  <span>Eliminar</span>
                </v-tooltip>
              </template>
              <template
                v-if="existInArray('Activar/Desactivar', currentPageActions)"
              >
                <v-tooltip bottom>
                  <template v-slot:activator="{ on, attrs }">
                    <v-icon
                      class="ml-5"
                      @click="
                        selectZone(item);
                        showMdlChangeStatus();
                      "
                      :color="
                        item.status == 1
                          ? 'cyan darken-3'
                          : 'blue-grey darken-1'
                      "
                      v-bind="attrs"
                      v-on="on"
                    >
                      {{
                        item.status == 1
                          ? "mdi-eye-outline"
                          : "mdi-eye-off-outline"
                      }}
                    </v-icon>
                  </template>
                  <span>
                    {{ item.status == 1 ? "Desactivar" : "Activar" }}
                  </span>
                </v-tooltip>
              </template>
            </template>
            <!-- end: Acions -->
          </v-data-table>
        </v-card>
        <!-- end: Datatable-->
      </div>
    </div>
    <!-- end::Card -->

    <!-- begin::Modals -->
    <CreateZoneModal ref="mdlCreate" @add="loadZones"></CreateZoneModal>

    <UpdateZoneModal
      ref="mdlUpdate"
      @update="loadZones"
      @clean-selection="unselectZone"
      :zone="selectedZone"
    ></UpdateZoneModal>

    <DeleteBaseModal ref="mdlDelete" @delete="deleteZone">
      <template v-slot:title>¿Desea eliminar esta zona?</template>
      <template v-slot:subject>
        la zona
        <span class="font-weight-bolder">{{ selectedZone.name }}</span>
      </template>
    </DeleteBaseModal>

    <ShowImg ref="mdlImg" :info="selectedZone">
      <template v-slot:title>Imagen {{ selectedZone.name }}</template>
      <template v-slot:subject>{{ selectedZone.name }}</template>
    </ShowImg>
  </div>
</template>

<script>
import zoneRepository from "@/repositories/zoneRepository";
import CreateZoneModal from "@/components/modals/zones/CreateZoneModal";
import UpdateZoneModal from "@/components/modals/zones/UpdateZoneModal";
import DeleteBaseModal from "@/components/modals/DeleteBaseModal.vue";
import ShowImg from "@/components/modals/ShowImg";
import ChangeStatusBaseModal from "@/components/modals/ChangeStatusBaseModal.vue";
import {
  GET_CURRENT_PAGE_ACTIONS,
  PURGE_CURRENT_PAGE_ACTIONS,
} from "@/core/services/store/role.module";
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import { mapGetters } from "vuex";

export default {
  name: "zones",
  title: "Zonas | GE ITR",
  components: {
    CreateZoneModal,
    UpdateZoneModal,
    DeleteBaseModal,
    ShowImg,
    ChangeStatusBaseModal,
  },
  data() {
    return {
      zones: {
        headers: [
          { text: "Nombre", value: "name" },
          { text: "Descripción", value: "description" },
          {
            text: "Imagen",
            value: "image",
            align: "center",
            sortable: false,
          },
          { text: "Acciones", value: "actions", sortable: false },
        ],
        data: [],
        search: "",
        isLoading: false,
      },
      selectedZone: {},
    };
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [{ title: "Zonas", route: "zones" }]);
    this.loadZones();
    /*Acá se hace referencia al permiso de las acciones - Despues se creará el de zona*/
    this.$store.dispatch(GET_CURRENT_PAGE_ACTIONS, "Zonas");
  },
  methods: {
    selectZone(obj) {
      // Creating a non reactive copie of data
      this.selectedZone = { ...obj };
    },
    unselectZone() {
      this.selectedZone = {};
    },
    showMdlCreate() {
      this.$refs.mdlCreate.toggleModal();
    },
    showMdlUpdate() {
      this.$refs.mdlUpdate.toggleModal();
    },
    showMdlDelete() {
      this.$refs.mdlDelete.toggleModal();
    },
    showImgDialog() {
      this.$refs.mdlImg.toggleModal();
    },
    loadZones() {
      this.zones.isLoading = true;
      zoneRepository
        .getAllZones()
        .then(({ data }) => {
          this.zones.data = data.data;
        })
        .catch(() => {
          this.fireToast({
            icon: "error",
            title: "No ha sido posible cargar los datos desde el servidor",
          });
        })
        .finally(() => {
          this.zones.isLoading = false;
        });
    },
    deleteZone() {
      zoneRepository
        .deleteZones(this.selectedZone.id, this.selectedZone)
        .then(({ data }) => {
          // Showing alert with response data
          this.sweetAlertResponse(data);
          // Retrieving data if operation has been completed
          if (data.status) {
            const filteredData = this.zones.data.filter(
              (item) => item.id !== this.selectedZone.id
            );
            this.zones.data = filteredData;
          }
        })
        .catch((error) => {
          // Sweetalert that indicates the operation was unsuccesfull
          this.fireToast({
            icon: "error",
            title: error.response.data.message,
          });
        });
    },
  },
  computed: {
    ...mapGetters(["currentPageActions"]),
  },
  beforeDestroy() {
    this.$store.commit(PURGE_CURRENT_PAGE_ACTIONS);
  },
};
</script>
