<template>
  <v-dialog
    v-model="dialog"
    persistent
    transition="dialog-bottom-transition"
    max-width="375"
  >
    <v-card>
      <v-card-title class="text-h6 font-weight-medium">
        <div class="d-flex flex-row">
          <v-icon medium color="blue" left>mdi-file-image</v-icon>
          <slot name="title" v-if="info.tempURL != ''"></slot>
          <p v-else class="text-body-1 font-weight-bold">
            No existe una imagen para este registro
          </p>
        </div>
      </v-card-title>
      <v-card-text class="text-body-1 black--text text-justify">
        Imagen de
        <slot name="subject" v-if="info.tempURL != ''"></slot>
        <p v-else class="font-weight-bold">
            No existe una imagen para este registro
          </p>
        <v-img
          contain
          lazy-src="media/bg/default.jpg"
          height="350"
          width="350"
          :src="info.tempURL != '' ? info.tempURL : `media/bg/vacio.png`"
        ></v-img>
      </v-card-text>
      <v-alert text prominent dense type="warning" v-if="info.tempURL == ''"
        >No hay una imagen registrada</v-alert
      >
      <v-divider></v-divider>
      <v-card-actions class="justify-end pt-0">
        <v-btn text outlined class="mr-1" @click="cancel">Cerrar</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script>
  export default {
    name: "ShowImg",
    props: {
      info: {
        type: Object,
      },
    },
    data() {
      return {
        isLoading: false,
        dialog: false,
      };
    },
    methods: {
      toggleModal() {
        this.dialog = !this.dialog;
      },

      cancel() {
        this.toggleModal();
      },
    },
  };
</script>
